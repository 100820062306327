<template>
  <form
    id="login-form"
    class="form"
    action="./bindphone"
    method="POST"
    @submit.prevent="handleSubmit"
  >
    <div class="log_main">
      <div class="bindPhine_title">
        <p>绑定手机号码</p>
      </div>
      <div class="log_ui logTips bindphone_tips">
        <p>帐号({{ account }})未绑定手机号码，请先绑定</p>
      </div>
      <div class="log_ui logTool">
        <div class="dsib log_tool_outer vm">
          <input
            class="txt username vm"
            type="text"
            id="phone"
            placeholder="输入手机号码"
            maxlength="11"
            required
            v-model="phoneNum"
          />
        </div>
      </div>
      <InputSms :directly="true" :phone="phoneNum" />
      <InputCaptcha />
      <div class="append_op dsib">
        <div class="dsib autoLogSet" id="err">{{ errmsg }}</div>
      </div>
      <div class="logBtnOuter">
        <BtnWithLoading type="submit" class="btn" :loading="isPending"
          >确定</BtnWithLoading
        >
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false,
      phoneNum: ''
    }
  },
  mounted() {
    if (!this.account) {
      this.$router.replace('/login')
    }
  },
  methods: {
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      payload['account_name'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['phone'] = this.phoneNum && this.encrypter.encrypt(this.phoneNum)
      payload['sms_code'] = this.sms && this.encrypter.encrypt(this.sms)

      this.isPending = true
      this.Error = ''
      this.$api
        .bindphone(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
